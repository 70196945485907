<template>
  <div>菜单管理</div>
</template>

<script type="text/ecmascript-6">
  export default {
    name: 'SettingModule'
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
